<template>
  <div>
    <!-- Error Handler Alert -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(value, valueIndex) in errors_back" :key="valueIndex">
          <li v-for="(val, valIndex) in value" :key="valIndex">{{ val }}</li>
        </ul>
      </div>
    </b-alert>
    <!-- validtion submit  -->
    <b-alert
      v-height-fade.appear
      fade
      :show="variantsCounter"
      @dismissed="variantsCounter = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">Please Add at least one variant</div>
    </b-alert>

    <!-- Filter Collapse Section-->
    <b-row>
      <b-col>
        <app-collapse accordion :type="collapseType" class="pb-1">
          <app-collapse-item title="تصنيف" class="rounded-lg">
            <div class="custom-search">
              <b-row class="py-1">
                <b-col md="4">
                  <b-form-group label="الماركة" label-for="Brands">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="brands"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      :state="brands == null ? false : true"
                      v-model="brandModel"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="الفئة" label-for="Category">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="categories"
                      v-model="categoriesModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="الحالة" label-for="Status">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="statusData"
                      v-model="statusModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="الدليل" label-for="Item">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="itemsBucket"
                      v-model="itemsModel"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="اقل كمية" label-for="Min Quantity">
                    <b-form-input
                      :min="minQuan"
                      :max="maxQuan"
                      v-model="minQuan"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="اكثر كمية" label-for="Max Quantity">
                    <b-form-input
                      :min="minQuan"
                      :max="maxQuan"
                      v-model="maxQuan"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="اقل سعر" label-for="Max">
                    <b-form-input
                      :min="minPrice"
                      :max="maxPrice"
                      :value="minPrice"
                      v-model="minPrice"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="اكثر سعر" label-for="Max">
                    <b-form-input
                      :min="minPrice"
                      :max="maxPrice"
                      :value="maxPrice"
                      v-model="maxPrice"
                      type="number"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <div class="py-1 d-flex justify-content-start">
                    <h4 class="fit-content">النوع :</h4>

                    <b-form-group class="ml-1" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        class="d-flex"
                        id="checkbox-group-2"
                        button-variant="outline-primary"
                        :aria-describedby="ariaDescribedby"
                        name="flavour-2"
                      >
                        <b-form-radio
                          v-model="selected_type"
                          :aria-describedby="ariaDescribedby"
                          name="product-type"
                          value="new"
                          >جديد</b-form-radio
                        >
                        <b-form-radio
                          class="ml-1"
                          v-model="selected_type"
                          :aria-describedby="ariaDescribedby"
                          name="product-type"
                          value="used"
                          >مستعمل</b-form-radio
                        >
                        <b-form-radio
                          class="ml-1"
                          v-model="selected_type"
                          :aria-describedby="ariaDescribedby"
                          name="product-type"
                          value="all"
                          >الكل</b-form-radio
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="py-1 d-flex justify-content-start">
                    <h4 class="fit-content">التصنيف :</h4>

                    <b-form-group class="ml-1" v-slot="{ ariaDescribedby }">
                      <b-form-checkbox-group
                        class="d-flex"
                        id="checkbox-group-2"
                        button-variant="outline-primary"
                        :aria-describedby="ariaDescribedby"
                        name="flavour-2"
                      >
                        <b-form-radio
                          v-model="selected_portal"
                          :aria-describedby="ariaDescribedby"
                          name="some-radios"
                          :value="true"
                          >بيع مباشر</b-form-radio
                        >
                        <b-form-radio
                          class="ml-1"
                          v-model="selected_portal"
                          :aria-describedby="ariaDescribedby"
                          name="some-radios"
                          :value="false"
                          >بيع علي المنصة</b-form-radio
                        >
                        <b-form-radio
                          class="ml-1"
                          v-model="selected_portal"
                          :aria-describedby="ariaDescribedby"
                          name="some-radios"
                          value="all"
                          >الكل</b-form-radio
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col md="12" class="d-flex justify-content-end">
                  <b-button
                    class="px-3"
                    variant="outline-dark"
                    @click="resetFilter()"
                    >مسح البحث</b-button
                  >
                  <b-button class="btn-color ml-3" @click="fetchData()">
                    بحث
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row>
      <b-col cols="12">
        <b-card title="الفهرس" id="main-table">
          <!-- sorting action -->
          <b-row class="">
            <b-col md="6">
              <b-form-group
                :label="$t('Product_Index.sortable')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="d-flex align-items-center"
              >
                <b-input-group class="d-flex">
                  <b-form-select
                    id="sortBySelect"
                    @change="fetchData"
                    v-model="sortBy"
                    value="desc"
                    size="sm"
                    :options="sortOptionss"
                  >
                  </b-form-select>
                  <b-form-select
                    @change="fetchData"
                    v-model="orderBy"
                    value="updated_at"
                    size="sm"
                    :options="orderOptions"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- search input -->

            <b-col md="3">
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">{{ $t("Product_Index.Search") }}</label>
                    <b-form-input
                      v-model="searchTerm"
                      :placeholder="$t('Product_Index.Search')"
                      type="text"
                      class="d-inline-block"
                      @keyup="fetchData()"
                    />
                  </div>
                </b-form-group>
              </div>
            </b-col>
            <!-- <b-col md="2">
              <b-button
                style="height: 2.142rem; line-height: 0.5;"
                variant="primary"
                :to="{ path: '/Used/Product/Add' }"
              >
                <span class="text-nowrap">{{
                  $t("Add_Product.Add_Product")
                }}</span>
              </b-button>
            </b-col> -->
          </b-row>
          <!-- Table component -->
          <vue-good-table
            ref="my-table"
            :columns="columns"
            :rows="items"
            max-height="90vh"
            style="min-height: 30vh !important;"
            styleClass="vgt-table striped"
            theme="polar-bear"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :sort-options="{
              enabled: false,
            }"
            :pagination-options="{
              enabled: true,
              perPage: perPage,
              setCurrentPage: currentPage,
            }"
          >
            <!-- Slot: Table Row -->
            <template slot="table-row" slot-scope="props">
              <!-- Column: cover -->
              <div
                class="d-block h-100 p-1"
                v-if="props.column.field === 'item_cover'"
              >
                <b-img-lazy
                  width="auto"
                  height="50"
                  :src="props.row.item_cover"
                />
              </div>
              <!-- Column: id -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'id'"
              >
                {{ props.row.id }}
              </div>
              <!-- Column: SKU -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'sku'"
              >
                {{ props.row.sku }}
              </div>

              <!-- Column: imei -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'imei'"
              >
                {{ props.row.imei }}
              </div>
              <!-- Column: status -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'status'"
              >
                <b-badge
                  :class="[
                    props.row.status === 'enabled' ? 'bg-primary' : '',
                    props.row.status === 'review' ? 'bg-success' : '',
                    props.row.status === 'disabled' ? 'bg-dark' : '',
                    props.row.status === 'rejected' ? 'bg-danger' : '',
                  ]"
                >
                  {{ props.row.status }}
                </b-badge>
              </div>
              <!-- Column: brand_logo -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'brand_logo'"
              >
                <b-img-lazy
                  width="auto"
                  height="20"
                  :src="props.row.brand_logo"
                />
              </div>
              <!-- Column: category -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'category_name'"
              >
                <b-badge variant="info">
                  {{ props.row.category_name }}
                </b-badge>
              </div>
              <!-- Column: Item -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'item_name'"
              >
                {{ props.row.item_name }}
              </div>

              <!-- Column: is_new -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'is_new'"
              >
                <b-badge :variant="props.row.is_new ? 'success' : 'warning'">
                  {{ props.row.is_new ? "جديد" : "مستعمل" }}
                </b-badge>
              </div>
              <!-- Column: is_portal -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'is_portal'"
              >
                <b-badge
                  :variant="props.row.is_portal ? 'primary' : 'secondary'"
                >
                  {{ props.row.is_portal ? "بيع منصة" : "بيع مباشر" }}
                </b-badge>
              </div>

              <!-- Column: is_insurance -->
              <div
                class="d-block h-100 p-1"
                     v-else-if="props.column.field === 'is_insurance'">
                <img  v-if="props.row.is_insurance " width="25" class="mx-1" :src="require('@/assets/images/icons/insurance.png')" />
              </div>
              
              <!-- Column: Name -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'product_name'"
              >
                {{ props.row.product_name }}
              </div>
              <!-- Column: Battery Health -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'battery_health'"
              >
                {{ props.row.battery_health }}
              </div>
              
              <!-- Column: sim cart value -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'sim_cart_value'"
              >
                {{ props.row.sim_cart_value }}
              </div>
              <!-- Column: Warranty-->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'warranty_type'"
              >
              {{ props.row.warranty_type }}
              </div>
              <!-- Column: Quantity	 -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'quantity'"
              >
                {{ props.row.quantity }}
              </div>
              <!-- Column: original price -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'original_price'"
              >
                {{ formatPrice(props.row.original_price) }}
              </div>
              <!-- Column: sale price -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'sale_price'"
              >
                {{ formatPrice(props.row.sale_price) }}
              </div>
              <!-- Column: original Discount -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'offer_amount'"
              >
                {{ formatPrice(props.row.offer_amount) }}
              </div>
              <!-- Column: sale profit -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'profit'"
              >
                {{ formatPrice(props.row.profit) }}
              </div>
              <!-- Column: Cretaed At -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'created_at'"
              >
                {{ props.row.created_at }}
              </div>
              <!-- Column: Updated At -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'updated_at'"
              >
                {{ props.row.updated_at }}
              </div>
              <!-- Column: Action -->
              <div
                class="d-block h-100 p-1"
                v-else-if="props.column.field === 'actions'"
              >
                <b-button
                  style="padding: 7px; margin: 6px; width: 35px; height: 35px;"
                  class="btn-icon rounded-circle"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
              :to="{ path:((props.row.is_new) ?'/New/ShowProduct/variant/show/' : '/Used/ShowProduct/variant/show/') + props.row.id }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px;"
                    icon="EyeIcon"
                  />
                </b-button>
              </div>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="perPage"
                    :options="['50', '100', '500']"
                    class="mx-1"
                    @input="filteredData()"
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <!-- pagination -->
                <div>
                  <b-pagination
                    :field="1"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="filteredData()"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- update price in table for variant -->
  </div>
</template>

<script>

import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import axios from '@/libs/axios'
import "vue-good-table/dist/vue-good-table.css";


export default {
  components: {
    
    VueGoodTable,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      required,
      newWebsiteLink: "https://mobilemasr.com/products/new/show?slug=",
      usedWebsiteLink: "https://mobilemasr.com/products/used/show?slug=",
      variantData: null,
      varaintDataPrice: {
        vendorPayout: null,
        portalPrice: null,
        rateEGP: null,
        rateInPercent: null,
      },
      actionForm: {
        originalPrice: "",
        offerAmount: "",
        rate: "",
        rateType: "",
        offerType: "",
        userNumber: "",
        startDate: "",
        endDate: "",
        bulkOptions: ["disabled", "rejected", "blocked"],
      },
      filterForm: {
        brands: [],
        vendors: [],
        categories: [],
        status: [],
        items: [],
        minQuantity: "",
        maxQuantity: "",
        minPrice: "",
        maxPrice: "",
        selected: [],
        selected_protal: [],
      },
      selectedRowsId: [],
      variantsBucket: [],

      bulkActionStatus: [],
      offerTypeOptions: [
        {
          text: "Fixed Price",
          value: "fixed_price",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      rateTypeOptions: [
        {
          text: "Fixed Price",
          value: "fixed_price",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
      ],
      brandsData: "",
      brands: [],
      brandModel: {
        text: "أختر الماركة",
        value: "",
      },
      vendors: [],

      categoriesData: "",
      categories: [],
      categoriesModel: { text: "أختر الفئة", value: "" },
      statusData: [],
      statusModel: "",
      itemsData: "",
      itemsBucket: [],
      itemsModel: { text: "اختر الدليل", value: "" },
      minQuan: 0,
      maxQuan: 10,
      minPrice: "",
      maxPrice: "",
      type: "",
      item_cover: "",
      offer_amount: "",
      selected_type: "all",
      selected_portal: "all",
      data: "",
      dateDefault: null,
      collapseType: "default",
      totalRows: 1,
      pageLength: 5,
      dir: false,
      // min_quantity: 0,
      // max_quantity: 10,
      sortBy: "desc",
      orderBy: "updated_at",
      orderOptions: [
        {
          text: "السعر",
          value: "price",
        },
        {
          text: "الأسم",
          value: "name",
        },
        {
          text: "أنشئت في",
          value: "created_at",
        },
        {
          text: "تم التحديث في",
          value: "updated_at",
        },
      ],
      sortOptionss: [
        {
          text: this.$t("Product_Index.ASC"),
          value: "asc",
        },
        {
          text: this.$t("Product_Index.DESC"),
          value: "desc",
        },
      ],
      currentPage: 1,
      perPage: 100,
      dir: false,
      columns: [
        {
          label: "Id",
          field: "id",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "الصورة",
          field: "item_cover",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "SKU",
          field: "sku",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "imei",
          field: "imei",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "الحالة",
          field: "status",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          label: "الماركة",
          field: "brand_logo",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "الفئة",
          field: "category_name",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "الدليل",
          field: "item_name",
          tdClass: "text-center",
          thClass: "text-center",
          width: "200px",
        },

        {
          label: "النوع",
          field: "is_new",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "التصنيف",
          field: "is_portal",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "برنامج الحماية",
          field: "is_insurance",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "الأسم",
          field: "product_name",
          tdClass: "text-center",
          thClass: "text-center",
          thClass: "text-center",
          width: "200px",
        },
        {
          label: "نوع الشريحة",
          field: "sim_cart_value",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: 'الضمان',
          field: 'warranty_type',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: "نسبه البطاريه",
          field: "battery_health",
          tdClass: "text-center",
          thClass: "text-center",
        },

        {
          label: "الكمية",
          field: "quantity",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "سعر البائع",
          field: "original_price",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "سعر البيع النهائي",
          field: "sale_price",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "الخصم",
          field: "offer_amount",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "عمولة الخدمة",
          field: "profit",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "أنشئت في",
          field: "created_date",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "تم التحديث في",
          field: "updated_date",
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "الصلاحيات",
          field: "actions",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      items: [],
      errors_back: [],
      showDismissibleAlert: false,
      variantsCounter: false,
      searchTerm: "",
      exportItems: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our columns
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
    rows() {
      return this.totalRows;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.filteredData();
  },
  methods: {
    fetchData() {
      this.items.length = 0;
      this.currentPage = 1;

      var url = "variants/data-table";
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
        // sort: this.sortBy,
        // order_by: this.orderBy,
      };
      if (this.orderBy != "") {
        data["order_by"] = this.orderBy;
      }
      if (this.sortBy != "") {
        data["sort"] = this.sortBy;
      }
      if (this.searchTerm != "") {
        data["search"] = this.searchTerm;
      }
      if (this.brandModel.value != null && this.brandModel.value != "") {
        data["brand_id"] = this.brandModel.value;
      }
      if (
        this.categoriesModel.value != null &&
        this.categoriesModel.value != ""
      ) {
        data["category_id"] = this.categoriesModel.value;
      }
      if (this.itemsModel.value != null && this.itemsModel.value != "") {
        data["item_id"] = this.itemsModel.value;
      }

      if (this.selected_type == 'new' || this.selected_type == 'used') {
       
       data['variant_type'] = this.selected_type
     }
      if (this.selected_portal == true || this.selected_portal == false) {
        data["is_portal"] = this.selected_portal;
      }
      if (this.statusModel != "" && this.statusModel != null) {
        data["status"] = this.statusModel;
      }
      if (this.minPrice != null) {
        data["min_price"] = this.minPrice;
      }
      if (this.maxPrice != null) {
        data["max_price"] = this.maxPrice;
      }
      if (this.maxPrice <= this.minPrice) {
        this.errors_back = [];
        this.showDismissibleAlert = true;
        this.errors_back.push([["Max price must be greater than min price"]]);
        setTimeout(() => {
          this.showDismissibleAlert = false;
        }, 3000);
      }
      if (this.maxQuan <= this.minQuan) {
        this.errors_back = [];
        this.showDismissibleAlert = true;
        this.errors_back.push([
          ["Max quantity must be greater than min quantity"],
        ]);
        setTimeout(() => {
          this.showDismissibleAlert = false;
        }, 3000);
      }
      axios
        .post(url, data)
        .then((response) => {
          const data = response.data.data.data;
          this.totalRows = response.data.data.total;
          this.items.length = 0;
          this.items = response.data.data.data;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              });
            } else {
              this.errors_back = err.response.data.data.status;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
      if (this.items.length == 0) {
        this.items = [];
      } else {
        return this.items;
      }
    },
    fetchFilterData() {
      axios
        .get("variants/data-table/filter")
        .then((response) => {
          this.brandsData = response.data.data.brands;
          this.brandsData.forEach((el) => {
            return this.brands.push({
              text: el.name,
              value: el.id.toString(),
            });
          });

          this.categoriesData = response.data.data.categories;
          this.categoriesData.forEach((el) => {
            return this.categories.push({
              text: el.name,
              value: el.id.toString(),
            });
          });
          this.itemsData = response.data.data.items;
          this.itemsData.forEach((el) => {
            return this.itemsBucket.push({
              text: el.name,
              value: el.id.toString(),
            });
          });
          this.statusData = response.data.data.status;
          this.minQuan = response.data.data.min_quantity;
          this.maxQuan = response.data.data.max_quantity;
          this.minPrice = response.data.data.min_price;
          this.maxPrice = response.data.data.max_price;
          this.type = response.data.data.type;
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              });
            } else {
              this.errors_back = err.response.data.data.status;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    filteredData() {
      this.items.length = 0;
      var url = "variants/data-table" + "?page=" + this.currentPage;
      var data = {
        min_quantity: this.minQuan,
        max_quantity: this.maxQuan,
        pagi: this.perPage,
      };
      if (this.brandModel.value != null && this.brandModel.value != "") {
        data["brand_id"] = this.brandModel.value;
      }
      if (
        this.categoriesModel.value != null &&
        this.categoriesModel.value != ""
      ) {
        data["category_id"] = this.categoriesModel.value;
      }
      if (this.itemsModel.value != null && this.itemsModel.value != "") {
        data["item_id"] = this.itemsModel.value;
      }

      if (this.selected_portal == true || this.selected_portal == false) {
        data["is_portal"] = this.selected_portal;
      }
      if (this.selected_type == 'new' || this.selected_type == 'used') {
       
       data['variant_type'] = this.selected_type
     }
      if (this.statusModel != "" && this.statusModel != null) {
        data["status"] = this.statusModel;
      }
      if (this.minPrice != null) {
        data["min_price"] = this.minPrice;
      }
      if (this.maxPrice != null) {
        data["max_price"] = this.maxPrice;
      }
      if (this.searchTerm != "") {
        data["search"] = this.searchTerm;
      }
      axios
        .post(url, data)
        .then((response) => {
          const data = response.data.data.data;
          this.totalRows = response.data.data.total;
          this.items.length = 0;
          this.items = response.data.data.data;

          this.fetchFilterData();
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (err.response.data.data) {
              this.errors_back.push({
                error: err.response.data.data.status,
              });
            } else {
              this.errors_back = err.response.data.data.status;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },

    resetFilter() {
      this.minQuan = 0;
      this.maxQuan = 20;
      this.minPrice = 100;
      this.maxPrice = 100000;

      this.brandModel = {
        text: "أختر الماركة",
        value: "",
      };
      this.categoriesModel = {
        text: "أختر الفئة",
        value: "",
      };

      this.itemsModel = {
        text: "أختر الدليل",
        value: "",
      };
      this.statusModel = "";
      this.filteredData();
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vgt-table thead th,
.vgt-table td,
.vgt-table td span {
  white-space: nowrap !important;
  text-transform: capitalize !important;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #021b3a !important;
  background-color: #021b3a !important;
}

.btn-color {
  background-color: #021b3a !important;
  border-color: #021b3a !important;
}
table.vgt-table td {
  padding: 0 !important;
}
.vgt-table {
  min-height: 37vh !important;
}
</style>
